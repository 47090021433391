<template>
  <section>
    <div class="mobile-filter-buttons my10">
      <button 
        class="primary-btn d-flex align-items-center mx5"
        @click="openFilterModal"
      >
        {{ $t('REPORT_SALES.SEARCH') }}
        <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img">
      </button>
    </div>
    <section class="filter desktop-filter">
      <div class="filter-item type-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <p class="mx5">{{ $t("FLIGHTS.TYPE") }}</p>
        </label>
        <vue-select
          class="input cus-select border-option-dropdown bd-radius"
          :options="type.options"
          :clearable="false"
          :clearSearchOnSelect="false"
          :searchable="false"
          :reduce="t => t.code"
          v-model="type.selected"
        ></vue-select>
      </div>
      <div class="filter-item cabin-class-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <p class="mx5">{{ $t("FLIGHTS.CABIN_CLASS") }}</p>
        </label>
        <vue-select
          class="input cus-select border-option-dropdown bd-radius"
          :options="cabin_class.options"
          :clearable="false"
          :clearSearchOnSelect="false"
          :searchable="false"
          :reduce="cc => cc.code"
          v-model="cabin_class.selected"
        ></vue-select>
      </div>
      <div class="filter-item travelers-input p-relative"
           v-click-outside="hidePassengerPopup">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/people.svg" alt="">
          <p class="mx5">{{ $t("FLIGHTS.PASSENGERS") }}</p>
        </label>
        <input
                class="input bd-radius text-center"
                type="text"
                v-model="totalPassenger"
                @click="totalPassengerClickHandler"
                readonly
        />
        <div class="passengers-list bg-white p10 bd-radius" v-show="isShowPassenger">
          <p>
            <b>{{ $t("FLIGHTS.PASSENGERS") }}</b>
          </p>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/account.svg" alt="" class="user-icon">
              <div>
                <p><b>{{ $t("FLIGHTS.ADULTS") }}</b></p>
                <p class="fs12">{{ $t("FLIGHTS.OVER_11") }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassengers('adults', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{adults}}
              </p>
              <div class="plus-btn df-c" @click="changePassengers('adults', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/face-man-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>{{ $t("FLIGHTS.CHILDREN") }}</b></p>
                <p class="fs12">{{ $t("FLIGHTS.AGE_2_11") }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassengers('children', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{children}}
              </p>
              <div class="plus-btn df-c" @click="changePassengers('children', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/baby-face-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>{{ $t("FLIGHTS.INFANTS") }}</b></p>
                <p class="fs12">{{ $t("FLIGHTS.UNDER_2") }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassengers('infants', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{infants}}
              </p>
              <div class="plus-btn df-c" @click="changePassengers('infants', '+')">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-item baggage-input p-relative"
           v-click-outside="hideBaggagePopup">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/hand_bag.svg" alt="" class="color-invert">
          <p class="mx5">{{ $t("FLIGHTS.BAGGAGE") }}</p>
        </label>
        <input
                class="input bd-radius text-center"
                type="text"
                v-model="totalBaggage"
                @click="totalBaggageClickHandler"
                readonly
        />
        <div class="baggage-list bg-white p10 bd-radius" v-show="isShowBaggage">
          <p>
            <b>{{ $t("FLIGHTS.BAGGAGE") }}</b>
          </p>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/hold_bag.svg" alt="" class="user-icon">
              <div>
                <p><b>{{ $t("FLIGHTS.HOLD_BAGS") }}</b></p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changeBaggage('hold', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{hold_bags}}
              </p>
              <div class="plus-btn df-c" @click="changeBaggage('hold', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/hand_bag.svg" alt="" class="user-icon">
              <div>
                <p><b>{{ $t("FLIGHTS.HAND_BAGS") }}</b></p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changeBaggage('hand', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{hand_bags}}
              </p>
              <div class="plus-btn df-c" @click="changeBaggage('hand', '+')">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-item max-stopovers-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <p class="mx5">{{ $t("FLIGHTS.MAX_STOPOVERS") }}</p>
        </label>
        <vue-select
          class="input cus-select border-option-dropdown bd-radius"
          :options="max_stopovers.options"
          :clearable="false"
          :clearSearchOnSelect="false"
          :searchable="false"
          :reduce="ms => ms.code"
          v-model="max_stopovers.selected"
        ></vue-select>
      </div>

      <div class="flex-break"></div>

      <div class="filter-item from-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5">{{ $t("FLIGHTS.FROM") }}</p>
        </label>
        <TreeSelect
          v-if="from.options"
          cclass="input bd-radius cus-select border-option-dropdown"
          v-model="from.selected"
          :defaultOptions="from.options"
          :placeholder="$t('FLIGHTS.FROM')"
          :async="true"
          :load-options="fetchLocationOptions"
          :disabled="isDestinationsFlightsPage"
          ref="from"
        ></TreeSelect>
      </div>
      <div class="filter-item to-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5">{{ $t("FLIGHTS.TO") }}</p>
        </label>
        <TreeSelect
          v-if="to.options"
          cclass="input bd-radius cus-select border-option-dropdown"
          v-model="to.selected"
          :defaultOptions="to.options"
          :multiple="true"
          :placeholder="$t('FLIGHTS.FOR_EXAMPLE', {dest_name: destinationExample})"
          :async="true"
          :load-options="fetchLocationOptions"
          :disabled="isDestinationsFlightsPage"
        ></TreeSelect>
      </div>
      <div class="filter-item range-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="">
          <p class="mx5">{{ $t("COMMON.DATES") }}</p>
        </label>
        <DateRangePicker
                :ranges="false"
                :linkedCalendars="true"
                v-model="dateRange"
                class="range-datepicker"
        >
        </DateRangePicker>
      </div>
      <button class="button filter__button bd-radius" @click="clickFilterBtnHandler()"></button>
    </section>
    <section class="mobile-filter-modal" v-show="isFilterModalVisible">
      <p class="fs20 color-white text-center m-b-10">{{ $t("TOURISM.SEARCH_YOUR_DESIRE") }}</p>
      <div class="filter-item m-t-15 deal-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <p class="mx5 color-white">{{ $t("FLIGHTS.TYPE") }}</p>
        </label>
        <vue-select
          :options="type.options"
          class="input cus-select border-option-dropdown bd-radius"
          v-model="type.selected"
          :placeholder="$t('COMMON.SELECT')"
        ></vue-select>  
      </div>
      <div class="filter-item m-t-15 from-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.FROM") }}</p>
        </label>
        <input class="input bd-radius" type="text" v-model="from" />
      </div>
      <div class="filter-item m-t-15 to-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.TO") }}</p>
        </label>
        <vue-select
          v-if="to.options"
          :options="to.options"
          class="input bd-radius cus-select border-option-dropdown"
          v-model="to.selected"
        ></vue-select>
      </div>
      <div class="filter-item m-t-15 range-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="">
          <p class="mx5 color-white">{{ $t("COMMON.DATES") }}</p>
        </label>
        <date-range-picker v-model="dateRange" class="range-datepicker">
        </date-range-picker>
      </div>
      <div class="filter-item m-t-15 travelers-input p-relative">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/people.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.TRAVELERS") }}</p>
        </label>
        <input 
          class="input bd-radius text-center" 
          type="text" 
          v-model="totalPassenger" 
          @click="totalPassengerClickHandler"
          readonly
        />
        <div class="passengers-list bg-white p10 bd-radius" v-show="isShowPassenger">
          <p>
            <b>Passengers</b>
          </p>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/account.svg" alt="" class="user-icon">
              <div>
                <p><b>Adults</b></p>
                <p class="fs12">Obber 11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassengers('adults', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{adults}}
              </p>
              <div class="plus-btn df-c" @click="changePassengers('adults', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/face-man-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Children</b></p>
                <p class="fs12">age 2-11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassengers('children', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{children}}
              </p>
              <div class="plus-btn df-c" @click="changePassengers('children', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/baby-face-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Infants</b></p>
                <p class="fs12">Under 2</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassengers('infants', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{infants}}
              </p>
              <div class="plus-btn df-c" @click="changePassengers('infants', '+')">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center filter-action-block m-t-10">
        <button class="primary-btn" @click="clickFilterBtnHandler()">
          {{ $t('REPORT_SALES.SEARCH') }}
          <img 
            src="/assets/img/icons/search-light-white.svg" 
            alt="" 
            class="seach-btn-img"
          >
        </button>
        <button 
          class="primary-btn bg-red6"  
          @click="closeFilterModal()"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import DateRangePicker from "@/components/FlightsDateRangePicker"
// import "../components/dateRangePicker2/assets/daterangepicker.scss";
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'TourismFlightsFilter',
  components: { DateRangePicker },
  props: {},
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);

    return {
      type: {
        options: [
          {
            code: 'round',
            label: this.$t("FLIGHTS.TYPE_ROUND")
          },
          {
            code: 'oneway',
            label: this.$t("FLIGHTS.TYPE_ONEWAY")
          },
        ],
        selected: 'round'
      },
      cabin_class: {
        options: [
          {
            code: '',
            label: this.$t("FLIGHTS.CABIN_CLASS_ANY")
          },
          {
            code: 'economy',
            label: this.$t("FLIGHTS.CABIN_CLASS_ECONOMY")
          },
          {
            code: 'economy_premium',
            label: this.$t("FLIGHTS.CABIN_CLASS_ECONOMY_PREMIUM")
          },
          {
            code: 'business',
            label: this.$t("FLIGHTS.CABIN_CLASS_BUSINESS")
          },
          {
            code: 'first_class',
            label: this.$t("FLIGHTS.CABIN_CLASS_FIRST")
          },
        ],
        selected: 'economy'
      },
      max_stopovers: {
        options: [
          {
            code: '0',
            label: this.$t("FLIGHTS.MAX_STOPOVERS_0")
          },
          {
            code: '1',
            label: this.$t("FLIGHTS.MAX_STOPOVERS_1")
          },
          {
            code: '2',
            label: this.$t("FLIGHTS.MAX_STOPOVERS_2")
          },
        ],
        selected: '1'
      },
      from: {
        defaultCode: 'c:TLV',
        options: null,
        selected: null,
      },
      to: {
        options: null,
        selected: null,
      },
      dateRange: { 
        startDate: startDate, 
        startDateFlex: 0,
        endDate: endDate,
        endDateFlex: 0,
        isRangeNight: false,
        nightsFrom: 2,
        nightsTo: 7,
      },
      isShowPassenger: false,
      travelers: 1,
      adults: 1,
      children: 0,
      infants: 0,
      isShowBaggage: false,
      hold_bags: 0,
      hand_bags: 1,
      isFilterModalVisible: false,
      destination_examples: [
        this.$t("FLIGHTS.LONDON"),
        this.$t("FLIGHTS.NEW_YORK"),
        this.$t("FLIGHTS.BERLIN"),
        this.$t("FLIGHTS.ROME"),
      ],
      destination_example_index: 0,
      destinationExampleCarouselInterval: null,
      filtersInUse: null
    }
  },
  computed: {
    ...mapState({
      // loading: (state) => state.flights.loading,
    }),
    ...mapGetters('flights', {
      flights: 'flights',
    }),

    isDestinationsFlightsPage() {
      return this.$route && this.$route.name && this.$route.name === 'DestinationsFlights';
    },

    destinationExample() {
        return '"' + this.destination_examples[this.destination_example_index] + '"';
    },
    totalPassenger() {
      return this.adults + this.children + this.infants;
    },
    totalAdultsChildrenPassenger() {
      return this.adults + this.children;
    },
    totalBaggage() {
      return this.hold_bags + this.hand_bags;
    },
  },
  methods: {
    ...mapActions('flights', {
      searchFlights: 'searchFlights',
      searchLocationOptions: 'searchLocationOptions',
    }),
    ...mapActions("alert", {
      errorAlert: "error"
    }),

    startDestinationExampleCarouselInterval() {
      let obj = this;
      if (obj.destinationExampleCarouselInterval) {
        return;
      }

      obj.destinationExampleCarouselInterval = setInterval(() => {
        obj.destination_example_index = obj.destination_example_index + 1 > obj.destination_examples.length - 1
          ? 0 : obj.destination_example_index + 1;
      }, 3000);
    },
    clearDestinationExampleCarouselInterval() {
      clearInterval(this.destinationExampleCarouselInterval);
      this.destinationExampleCarouselInterval = null;
    },
    async clickFilterBtnHandler() {
        await this.initSearchFlightsProcess();
    },
    async initSearchFlightsProcess() {
        if (!this.type.selected
            || !this.max_stopovers.selected
            || !this.adults
            || !this.from.selected
            || !this.to.selected
            || !this.to.selected.length
        ) {
            this.errorAlert(this.$t("FLIGHTS.PLEASE_USE_ALL_FILTERS"));
            return false;
        }

        const filtersToUse = {
            type: this.type.selected,
            cabin_class: this.cabin_class.selected,
            max_stopovers: this.max_stopovers.selected,
            adults: this.adults,
            children: this.children,
            infants: this.infants,
            hold_bags: this.hold_bags,
            hand_bags: this.hand_bags,
            from: this.from.selected,
            to: this.to.selected.join(','),
            start_date: this.dateRange.startDate,
            start_date_flex: this.dateRange.startDateFlex,
            end_date: this.dateRange.endDate,
            end_date_flex: this.dateRange.endDateFlex,
            nights_from: this.dateRange.nightsFrom,
            nights_to: this.dateRange.nightsTo,
            is_range_night: this.dateRange.isRangeNight,
            one_per_city: 0,
            one_per_date: 0,
        };

        // console.log(filtersToUse);

        try {
          await this.$router.push({ path: this.$route.path, query: {
              ...filtersToUse
            }
          });
        } catch (e) {
            // console.log('oops! router push is failed!')
        }

        this.filtersInUse = filtersToUse;

        this.searchFlights({filters: filtersToUse});
        // todo: navigate to another page if needed?
    },
    totalPassengerClickHandler(e) {
      this.togglePassengerPopup();
    },
    togglePassengerPopup() {
      this.isShowPassenger = !this.isShowPassenger;
    },
    totalBaggageClickHandler(e) {
      this.toggleBaggagePopup();
    },
    toggleBaggagePopup() {
      this.isShowBaggage = !this.isShowBaggage;
    },
    changePassengers(pType, changeType) {
      if (changeType === '+') {
        if (this.totalPassenger >= 9) {
          return;
        }

        if (pType === 'infants' && this.infants >= this.adults) {
          return;
        }

        this.setPassengers(pType, (this[pType] + 1));
      } else if (changeType === '-') {
        if (this[pType] === 0) {
          return;
        }

        this.setPassengers(pType, (this[pType] - 1));
      }
    },
    setPassengers(pType, count) {
      this[pType] = count;
    },
    changeBaggage(bType, changeType) {
      if (changeType === '+') {
        if (bType === 'hold' && this.hold_bags >= this.totalAdultsChildrenPassenger * 2) {
          return;
        }

        if (bType === 'hand' && this.hand_bags >= this.totalAdultsChildrenPassenger) {
          return;
        }

        this.setBaggage(bType, (this[bType + '_bags'] + 1));
      } else if (changeType === '-') {
        if (this[bType + '_bags'] === 0) {
          return;
        }

        this.setBaggage(bType, (this[bType + '_bags'] - 1));
      }
    },
    setBaggage(bType, count) {
      this[bType + '_bags'] = count;
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    },
    recalculatePassengersAndBaggage() {
      if (this.infants > this.adults) {
        this.setPassengers('infants', this.adults);
      }
      if (this.hold_bags > this.totalAdultsChildrenPassenger * 2) {
        this.setBaggage('hold', this.totalAdultsChildrenPassenger * 2);
      }
      if (this.hand_bags > this.totalAdultsChildrenPassenger) {
        this.setBaggage('hand', this.totalAdultsChildrenPassenger);
      }
    },
    hidePassengerPopup() {
      if (this.isShowPassenger) {
        this.isShowPassenger = false;
      }
    },
    hideBaggagePopup() {
      if (this.isShowBaggage) {
        this.isShowBaggage = false;
      }
    },
    fetchLocationOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        let searchParams = {
            search_term: searchQuery
        };
        this.searchLocationOptions({filters: searchParams}).then(
          (result) => {
            let loaded_items = result.data;
            const options = loaded_items.map(i => ({
              id: 'c:' + i.code,
              label: i.name + ' (' + i.country.name + ')',
              ...(i.airports && i.airports.length > 0 && {children: i.airports.map(a => ({
                  id: 'a:' + a.code,
                  label: a.name,
                })
              )})
            }));

            callback(null, options);
          },
          (error) => {
            callback(null, []);
          }
        );
      }
    },
    async initFilters() {
      let queryParameters = this.$route.query;

      if (queryParameters.type) {
        this.type.selected = queryParameters.type;
      }
      if (queryParameters.cabin_class) {
        this.cabin_class.selected = queryParameters.cabin_class;
      }
      if (queryParameters.max_stopovers) {
        this.max_stopovers.selected = queryParameters.max_stopovers;
      }
      if (queryParameters.adults) {
        this.adults = +queryParameters.adults;
      }
      if (queryParameters.children) {
        this.children = +queryParameters.children;
      }
      if (queryParameters.infants) {
        this.infants = +queryParameters.infants;
      }
      if (queryParameters.hold_bags) {
        this.hold_bags = +queryParameters.hold_bags;
      }
      if (queryParameters.hand_bags) {
        this.hand_bags = +queryParameters.hand_bags;
      }

      if (queryParameters.start_date) {
        this.dateRange.startDate = queryParameters.start_date;
      }
      if (queryParameters.start_date_flex) {
        this.dateRange.startDateFlex = +queryParameters.start_date_flex;
      }
      if (queryParameters.end_date) {
        this.dateRange.endDate = queryParameters.end_date;
      }
      if (queryParameters.end_date_flex) {
        this.dateRange.endDateFlex = +queryParameters.end_date_flex;
      }
      if (queryParameters.nights_from) {
        this.dateRange.nightsFrom = +queryParameters.nights_from;
      }
      if (queryParameters.nights_to) {
        this.dateRange.nightsTo = +queryParameters.nights_to;
      }
      if (queryParameters.is_range_night) {
        this.dateRange.isRangeNight = queryParameters.is_range_night === 'true' || queryParameters.is_range_night === '1';
      }

      await this.loadDefaultFromToOptions(queryParameters.from, queryParameters.to);
    },
    async loadDefaultFromToOptions(from_code, to_codes) {
      let obj = this;
      to_codes = to_codes ? to_codes : [];
      to_codes = Array.isArray(to_codes) ? to_codes : to_codes.split(',');
      from_code = from_code || obj.from.defaultCode;
      const cleanFromCode = from_code.includes(':')
          ? from_code.split(':')[1] : from_code;
      const cleanToCodes = to_codes.map(to_code => to_code.includes(':')
          ? to_code.split(':')[1] : to_code);
      let cleanCodes = [];
      if (cleanFromCode) {
          cleanCodes.push(cleanFromCode);
      }
      if (cleanToCodes && cleanToCodes.length) {
          cleanCodes = cleanCodes.concat(cleanToCodes);
      }

      if (!cleanCodes || !cleanCodes.length) {
          return;
      }

      await this.searchLocationOptions({filters: {search_by_code: cleanCodes.join(',')}}).then(
        (result) => {
          let loaded_items = result.data;
          let from_options = [];
          let to_options = [];
          for (let i = 0; i < loaded_items.length; i++) {
            let item = loaded_items[i];
            let option = {
              id: 'c:' + item.code,
              label: item.name + ' (' + item.country.name + ')',
              ...(item.airports && item.airports.length > 0 && {children: item.airports.map(a => ({
                  id: 'a:' + a.code,
                  label: a.name,
                })
              )})
            };

            if (option.id == from_code
              || option.airports && option.airports.length && option.airports.map(a => a.code).includes(from_code)) {
              from_options.push(option);
            }

            if (to_codes && to_codes.length && (to_codes.includes(option.id)
              || option.airports && option.airports.length && option.airports.filter(airport => to_codes.includes(airport.code)).length)) {
              to_options.push(option);
            }
          }

          obj.from.options = from_options;
          obj.to.options = to_options;

          if (from_options && from_options.length) {
            obj.from.selected = from_code;
          }
          if (to_options && to_options.length) {
            obj.to.selected = to_codes;
          }
        },
        (error) => {
          obj.from.options = [];
          obj.to.options = [];
        }
      );
    }
  },
  watch: {
    adults(newValue, oldValue) {
      if (newValue < oldValue) {
        this.recalculatePassengersAndBaggage();
      }
    },
    children(newValue, oldValue) {
      if (newValue < oldValue) {
        this.recalculatePassengersAndBaggage();
      }
    }
  },
  async created() {
    // lets try to init filters here???
    await this.initFilters();

    if (this.isDestinationsFlightsPage) {
      await this.initSearchFlightsProcess();
    }

    this.startDestinationExampleCarouselInterval();
  },
  mounted() {
  },
  beforeUnmount() {
    this.clearDestinationExampleCarouselInterval();
  },
  unmounted() {
    this.clearDestinationExampleCarouselInterval();
  },
  destroyed() {
    this.clearDestinationExampleCarouselInterval();
  },
}
</script>

<style lang="scss">
.filter {
  .range-datepicker .reportrange-text {
    height: 38px;
    border-radius: 5px;
  }
}

.reportrange-text {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>

<style lang="scss" scoped>
.filter {
  .color-invert {
    filter: invert(1);
  }
  /*width: 100%;*/
  flex-wrap: wrap;
  .flex-break {
    flex-basis: 100%;
    margin: 10px 0;
  }
  label {
    color: white;
  }
  .type-input,
  .cabin-class-input,
  .travelers-input,
  .baggage-input,
  .max-stopovers-input {
    flex-basis: 10%;
    min-width: 200px;
  }
  .from-input,
  .to-input {
    flex-basis: 18%;
    min-width: 400px;
  }
  .travelers-input, .baggage-input {
    width: 10%;
    .input {cursor: pointer;}
    .passengers-list, .baggage-list {
      position: absolute;
      top: 80px;
      right: 0;
      font-size: 16px;
      width: 300px;
      z-index: 22;
      &:after {
        content: '';
        display: block;  
        position: absolute;
        right: 40px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 15px solid #fff;
        border-top: 15px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
      .minus-btn {
        width: 30px;
        height: 30px;
        background-color: #F2F2F2;
        cursor: pointer;
      }
      .plus-btn {
        width: 30px;
        height: 30px;
        background-color: #D9D9D9;
        cursor: pointer;
      }
      .user-icon {
        width: 35px;
      }
      .number-block {
        width: 35px;
      }
    }
  }
  .range-input {
    width: 25%;
  }
  &.no-type-deal {
    .deal-input {
      display: none;
    }
    .from-input,
    .to-input {
      width: 22%;
    }
    .range-input {
      width: 32%;
    }
    .travelers-input {
      width: 15%;
    }
  }
}

.mobile-filter-buttons {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop-filter {
    display: none;
  }
  .mobile-filter-buttons {
    display: block;
    .primary-btn {
      background-color: #002036;
      width: auto;
      font-size: 12px;
      font-weight: initial;
      height: auto;
      padding: 5px;
      border-radius: 8px;
      .seach-btn-img {
        height: 15px;
        margin-right: 10px;
      }
    }
  }
  .mobile-filter-modal {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    right: 0;
    background-color: #002036;
    height: calc(100vh - 60px);
    padding: 15px;
    z-index: 22;
    overflow: auto;
    .filter__item {
      padding: 0;
      height: unset;
      margin-top: 15px;
      &::after {
        display: none;
      }
      .cus-select {
        height: 40px;
        padding-top: 0;
      }
    }
    .filter-action-block {
      .primary-btn {
        padding: 0 20px;
        margin: 0 10px;
        border-radius: 5px;
        .seach-btn-img {
          margin-right: 15px;
        }
      }
    }
  }
  .ltr-type {
     .mobile-filter-buttons {
      .primary-btn {
        .seach-btn-img {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
    .mobile-filter-modal {
      .filter-action-block {
        .primary-btn {
          .seach-btn-img {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>