<template>
  <main
    class="tourism color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TourismFlightsFilter></TourismFlightsFilter>
    <router-view></router-view>
  </main>
</template>

<script>
import TourismFlightsFilter from "@/components/tourism/flight/TourismFlightsFilter.vue";

export default {
  name: "FligntsContainer",
  components: {
    TourismFlightsFilter,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss">
  @import "@/assets/scss/tourism.scss";
</style>
